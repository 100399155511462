<template>
  <section>
    <VToolbar
      class="pt-2 pb-2"
      color="primary"
      flat
      dense
      dark
    >
      <VToolbarTitle id="toolbarTitle">
        {{ pageTitle }}
      </VToolbarTitle>
    </VToolbar>
    <section id="main">
      <div id="info">
        <p class="textTitle">
          {{ data.title }}
        </p>
        <p class="textSubTitleNormal mt-2">
          {{ data.date }}
        </p>
        <p class="textSubTitleNormal">
          {{ data.class? `CL: ${data.class}` : '' }}
        </p>
        <p class="textSubTitleNormal mt-4">
          {{ data.body }}
        </p>
        <div
          v-if="data.imgUrls.length !== 0"
          class="imgContainer mt-5"
        >
          <div
            v-for="img in data.imgUrls"
            :key="img"
          >
            <VImg
              :src="img"
              :lazy-src="require('../../assets/images/loading.png')"
              alt="Image"
              class="imgContent"
              aspect-ratio="1"
              @click="openImg(img)"
            >
              <template v-slot:placeholder>
                <VLayout
                  fill-height
                  align-center
                  justify-center
                  ma-0
                >
                  <VProgressCircular
                    indeterminate
                    color="grey lighten-3"
                  />
                </VLayout>
              </template>
            </VImg>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  data: () => ({
    pageTitle: 'notification',
    data: '',
    dataArr: '',
  }),

  beforeMount() {
    this.dataArr = this.$store.state.notification.data
      .filter(data => data.id === this.$route.params.id);
    if (this.dataArr.length > 0) {
      this.data = this.dataArr[0].data;
    } else {
      this.$router.push('/');
    }
  },
  methods: {
    openImg(img) {
      window.open(img);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/text';
@import '../../assets/stylus/notification';
</style>
